import React, { useState, Fragment, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import loadable from '@loadable/component'
import { DesktopScreen, DefaultScreen } from './../../components/common/Screens'
import SearchTabs from './../../components/SearchTabs'
import { FeatureCardsContainer, HomeSearchWrap, HeaderTitle, SearchBarContainer } from './Home.styled'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import useCheckCountry from '../../hooks/useCheckCountry'

const FeatureCards = loadable(() => import('../../components/HomePage/FeatureCards'), {
  fallback: <FeatureCardsContainer />
})
const DownloadAppBanner = loadable(() => import('../../components/DownloadAppBanner'), {
  ssr: false
})
const BlogSlide = loadable(() => import('../../components/HomePage/BlogSlide'), {
  ssr: false
})
const Press = loadable(() => import('../../components/HomePage/Press'), {
  ssr: false
})
const Search = loadable(() => import('../../components/SearchBoxV3/CarSearch'), {
  ssr: false
})
const FAQsHomePage = loadable(() => import('../../components/HomePage/FAQsHomePage'), {
  ssr: false
})

const Home = ({ history }) => {
  const { t } = useTranslation()
  const checkCountry = useCheckCountry()
  const useCountry = useSelector((state) => state.user.country)

  const [display, setDisplay] = useState(true)
  const language = useSelector((state) => state.user.language)

  useEffect(() => {
    setDisplay(!['it', 'es', 'de', 'fr'].includes(language))
  }, [language])

  const scrollFunction = () => {
    const navbarHomePage = document.getElementById('NAVBAR-HOMEPAGE')
    if (!navbarHomePage) return

    if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
      navbarHomePage.classList.add('fixed-header')
      navbarHomePage.classList.remove('home-header')
    } else {
      navbarHomePage.style.background = `rgba(255,255,255,${document.documentElement.scrollTop / 30})`
      if (document.documentElement.scrollTop === 0) {
        navbarHomePage.classList.remove('fixed-header')
        navbarHomePage.classList.add('home-header')
      } else {
        navbarHomePage.classList.add('fixed-header')
        navbarHomePage.classList.remove('home-header')
      }
    }
  }

  const handleAnchor = (e) => {
    e.preventDefault()

    window.scroll({
      top: 0,
      behavior: 'smooth'
    })
  }

  useEffect(() => {
    const navbarHomePage = document.getElementById('NAVBAR-HOMEPAGE')
    if (navbarHomePage) {
      navbarHomePage.classList.add('home-header')
      window.addEventListener('scroll', scrollFunction)
    }

    return () => {
      const navbarHomePage = document.getElementById('NAVBAR-HOMEPAGE')
      if (navbarHomePage) {
        navbarHomePage.style.background = 'white'
        navbarHomePage.classList.remove('home-header')
        navbarHomePage.classList.remove('fixed-header')
      }

      window.removeEventListener('scroll', scrollFunction)

      const Anchor = document.getElementById('SEECARRENTALPRICES')
      if (Anchor) {
        Anchor.removeEventListener('click', handleAnchor)
      }
    }
  }, [])

  return (
    <Fragment>
      <Helmet>
        <title>{t('homePage:title')}</title>
        <link rel='canonical' href='https://rentcarla.com/' />
      </Helmet>
      <SearchBarContainer $us={useCountry === 'US'}>
        <HeaderTitle>{t('homePage:header')}</HeaderTitle>
        <DesktopScreen>
          <HomeSearchWrap>
            {display && <SearchTabs />}
            <Search history={history} />
          </HomeSearchWrap>
        </DesktopScreen>
        <DefaultScreen>
          <Search history={history} />
        </DefaultScreen>
      </SearchBarContainer>
      <FeatureCards />
      {checkCountry && <DownloadAppBanner />}
      {display && <BlogSlide />}
      <FAQsHomePage />
      <Press />
    </Fragment>
  )
}

export default Home
